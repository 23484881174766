import React, { useEffect, useState } from 'react'
import './home.css'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, getDoc, where, doc, query, orderBy, updateDoc } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import { BrowserRouter, Link, Router, useNavigate } from 'react-router-dom';
import { FaPencilAlt, FaRegImage, FaLock } from "react-icons/fa";





function Home() {
    
    let navigate = useNavigate()

    const db = getFirestore(app)
    const myauth = getAuth(app)

    const [progress, setprogress] = useState(true)
    const [arr, setarr] = useState([])
    const [searchtxt, setsearchtxt] = useState("")
    const [arr2, setarr2] = useState([])
    const [show, setshow] = useState(false)
    const [type, settype] = useState("agents")


    const myarray = []
    let array2 = []

    useEffect(() => {
        getagents()
    }, [])
    
    function logout(){
        console.log("out")
        myauth.signOut();
    }

    async function searchagent(name){
  
      setshow(true)
      array2 = []
      setarr2([])
      
      // const q = query(collection(db, "agents"), where("email", ">=", ""+name), where("email", "<=", name +'\uf8ff'));
     
      // const querySnapshot = await getDocs(q)

      // console.log(querySnapshot.size)
      // querySnapshot.forEach((doc1) => {
      //   console.log(doc1.id, " => ", doc1.data());
      //   let obj1 = {
      //     email: doc1.data().email,
      //     name: doc1.data().name,
      //     companyName: doc1.data().companyName,
      //     password: doc1.data().password
        
      //   }
      //   array2.push(obj1)
      //  console.log(array2)
      // });
      
      const q2 = query(collection(db, "users"), where("email", ">=", ""+name), where("email", "<=", name +'\uf8ff'));
      const querySnapshot2 = await getDocs(q2)
      querySnapshot2.forEach((doc2) => {
        console.log(doc2.id, " => ", doc2.data());
       
        array2.push(doc2.data())
       console.log(array2)
      
      });

      setarr2(array2)

    }



    async function updateLock(docid, value, pos){

      setprogress(true)
      try{


        const washingtonRef = doc(db, "agents", ""+docid);
        await updateDoc(washingtonRef, {
          lock: value
        })
        setprogress(false)

        if(value){
          alert("Unlocked Succesfull")
        }else{
          alert("Locked Succesfull")
        }
        
       
  

       getagents()


      }catch(e){
        setprogress(false)
        alert("Failed"+e)
      }
     

    }



   async function getagents() {

    
    const q = query(collection(db, "agents"), orderBy("createdAt", "desc"))

    setshow(false)
       const querySnapshot = await getDocs(q);
       setprogress(false)
        querySnapshot.forEach((doc) => {
           //  console.log(`${doc.id} => ${doc.data()}`);
             
           console.log(doc.data())

             myarray.push(doc)
           
           
        });

        setarr(myarray)
    }

    return (
        <div style={{padding:'20px'}}>
            {
                progress ?
                   <Progress/>

                       : null
            }

       

            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '12px' }}>

            <button onClick={()=>{navigate("/create")}}>Create Agent</button>
            <button onClick={()=>logout()}>Logout</button>
          

            </div>

            <h1 style={{textAlign: 'center'}}>Home</h1>

<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>

  <div>
  <input onChange={(x)=>{setsearchtxt(x.target.value)}} value={searchtxt} style={{width: '180px', padding: '12px' }} type='text' placeholder='search here'/>

        <button  
        style={{
          display: type == "agents" ? 'none' : null
        }}
        onClick={()=>{
          if(searchtxt == ""){
            alert("Please Search")
          }else{
            searchagent(searchtxt)}
          }
      
        }>Submit</button>
        <button 
         style={{
          display: type == "agents" ? 'none' : null
        }}
        onClick={()=>{setshow(false); setsearchtxt("")}}>Clear</button>

            


  </div>

            <div style={{width: '200px', padding: '2px'}}>
             <select style={{display: 'none'}} value={type} onChange={(x)=>{
               settype(x.target.value)

               setsearchtxt("")
               
               if(x.target.value == "users"){
                 setarr([])
                setshow(true)
               }else{
                setarr2([])

                 getagents()
               }
             }}>
            <option 
             value="agents">agents</option>
            <option value="users">users</option>
          
          </select>
            </div>

</div>
    
       
        
      <div style={{display: show ? 'none' : 'block'}}>


            <table  id="customers">
                            <thead>
                    
                            <tr>
                            
                            <th>Company Name</th>
                              <th>AgentId</th>
                          
                              <th>Password</th>
                              <th>Remarks</th>
                              <th>Logo</th>
                              <th style={{display: 'none'}}>Mobile</th>
                              <th>More</th>
                            
                            </tr>
                            </thead>
                            <tbody>
                    
                            {
                                arr.filter((x)=>{
                                  if(searchtxt == ""){
                                   return x
                                  }else if(x.data().email.toLowerCase().includes(searchtxt.toLowerCase()) || 
                                  x.data().companyName.toLowerCase().includes(searchtxt.toLowerCase()) ||
                                  x.data().remarks.toLowerCase().includes(searchtxt.toLowerCase())){
                                    return x
                                  }
                                  
                                })
                              .map((x, key)=>{
                              return(
                                <tr   key={key}>

                                <td
                                onClick={()=>{
                                  navigate('/details?uid='+x.data().uid+'&name='+x.data().name)
                                 }}
                                style={{color: 'blue', textDecorationLine: 'underline', cursor: 'pointer'}}
                                >{x.data().companyName}</td>
                                <td  
                                   >{x.data().email.replace("@gmail.com", "")}</td>
                           
                           
                               
                                <td>{x.data().password}</td>
                                <td>{x.data().remarks}</td>
                                <td style={{display: 'none'}}>{x.data().mobile}</td>
                                <th style={{background: 'white',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  <img 
                                  style={{width: '50px', height: '50px'}}
                                  src={x.data().logo} />
                                </th>
                                <td>
                                <FaPencilAlt 
                                   onClick={()=>{navigate('/edit?id='+x.data().uid)}}
                                style={{fontSize: '13px', cursor: 'pointer'}}
                                color='gray'/>

                                <FaRegImage  
                                onClick={()=>{
                                  navigate('/editLogo?id='+x.data().uid)
                                }}
                                color='gray'
                                style={{marginLeft: '15px', fontSize: '13px', cursor: 'pointer'}}/>
                            
                                  <FaLock 
                                  color={x.data().lock? 'black' : 'gray'}
                                  onClick={()=>{
                                      updateLock(x.id, !x.data().lock, key)
                                  }}
                                  style={{marginLeft: '12px', fontSize: '13px'}}/>
                                  </td>
                                 
                              </tr>
                              )
                            })}
                    
                            </tbody>
                           
                          </table>

                          </div>

                        <div style={{display: show ? 'block' : 'none'}}>

                        <table id="customers">
                            <thead>
                    
                            <tr>
                            
                              <th>Name</th>
                              <th>Remarks </th>
                              
                              <th>Password</th>
                              <th style={{display: 'none'}}>Mobile</th>
                           
                             
                            </tr>
                            </thead>
                            <tbody>
                    
                            {arr2.map((x, key)=>{
                              return(
                                <tr   key={key}>
                                <td>{x.email.replace("@gmail.com","")}</td>
                           
                                <td>{x.remark}</td>
                               
                                <td>{x.password}</td>
                           
              

                              </tr>
                              )
                            })}
                    
                            </tbody>
                           
                          </table>

                        </div>
                          


        </div>
    )
}

export default Home