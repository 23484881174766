import React, { useEffect, useState } from 'react'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, where, query, doc } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import { useNavigate } from 'react-router-dom';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import NewUsers from './NewUsers';


function NewChits({uid, name, title, body}) {

    let navigate = useNavigate()
    

    const db = getFirestore(app)
    const auth = getAuth(app)

    const [temp, settemp] = useState("")
    const [progress, setprogress] = useState(true)
    
    const [arr, setarr] = useState([])
    const [msg, setmsg] = useState("Loading")
    const [userarray, setuserarry] = useState([])

    const [borderstatus, setborderstatus] = useState("")

    const myarray = []
    const carray = []

    useEffect(() => {
        getagents()
    }, [])


 

          

   

    async function getagents() {

        const q = query(collection(db, "chitGroups"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
       setprogress(false)

       if(querySnapshot.size == 0){
           setmsg("No Chits")
       }else{
           
        querySnapshot.forEach((doc) => {
            //  console.log(`${doc.id} => ${doc.data()}`);
              
            console.log(doc.data())
 
             
            let mymodel = {mydata: doc.data(), mystatus: false}
 
              myarray.push(mymodel)
            
            
         });
 
         setarr(myarray)
       }
     
    }

  return (
    <div>

<div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>

           


</div>
        <h1 style={{margin: '16px'}}>{"Agent Name:- "+ name} </h1>

      <div style={{display: 'flex',  justifyContent: 'end'}}>
      <button onClick={()=>{ navigate('/createchit?uid='+uid) }} 
      style={{ marginLeft: '16px', marginRight: '12px', display: 'none'}}>Create Chit</button>
      </div>
       


        <div style={{padding:'20px'}}>
            {
                progress ?
                  <Progress/>
                       : null
            }


            {/* <div style={{width: '100px', height: '100px', backgroundColor: 'green',border: '2px solid black'}}>

            </div> */}


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

           

         
            </div> 

            {
              arr.length > 0 ?             <table 
            
              id="customers"> 
                      <thead>
                
                        <tr>
                          <th>Chit Id</th>
                          <th>Chit Name</th>
                         
                       
                        </tr>
                        </thead>
                        <tbody> 
                       </tbody>
                       
                       {
                          arr.map((x, key)=>{

                            const row =   <tr 

                            key={key}
                            onClick={()=>{
                              let old = [...arr]
                              settemp(x.mydata.chitName)
                        
                         
                              if(arr[key].mystatus == true){
                           
                                old[key].mystatus = false
                                setarr(old)
                              }else{
                  
                                old[key].mystatus = true
                                setarr(old)
                              }
                             
                            }}
                            
                            >
                            <td >{x.mydata.chitId}</td>
                            <td>{x.mydata.chitName}</td>
                            

                          </tr>
                            return <React.Fragment>
                                  {row}
                                  {
                                    x.mystatus   ? 
                                  
                                      <NewUsers mchitid={x.mydata.chitId} title={title} body={body} chitname={x.mydata.chitName} />
                                          
                                    :null
                                  }
                            </React.Fragment>
                            
                            
                            
                          })
                       }
                      </table>  : <p>No Chits</p>
            }

                    



                   {/* <ScrollMenu>

                       {
                           arr.length == 0 ? <p>{msg}</p> : 

                           arr.map((x, key)=>{
                            return(

                           
                              <div 
                              key={key} 
                              onClick={()=>{
                                settemp(x.chitName)
                            
                                getchit(x.chitId)
                                setborderstatus(x.chitName)
                              }}
                              style={{ width: '120px', background: '#'+x.chitColor, margin: '10px', padding: '5px',
                            border: x.chitName == borderstatus ? '2px solid black': '0px solid white' , borderRadius: '10px'}}> 


                          

                              <p style={{color: 'black', marginTop: '-3px'}}>{x.chitName}</p>
                              <p style={{color: 'black', marginTop: '-10px'}}>{x.count}</p>
                            

                          
                              </div>

                              
                           
                            )
                          })

                       }
                
                   </ScrollMenu> */}
                        
                    
      


        </div>
   


    </div>


  )
}

export default NewChits