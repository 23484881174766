import React, { useEffect, useState } from 'react'
import './chitedit.css'
import { addDoc, collection, doc, getFirestore, serverTimestamp, setDoc, writeBatch, getDoc, updateDoc } from "firebase/firestore";
import { app } from '../Fire';
import { async } from '@firebase/util';
import Progress from '../Progress/Progress';

const ChitEdit = () => {


    

    const db = getFirestore(app)
    const [zid, setzid] = useState("")
    const [password, setpassword] = useState("")


   

    const [count, setcount] = useState()
    const [progress, setprogress] = useState(false)
    const [cname, setcname] = useState("")
    const [prevcount, setprevcount] = useState()

    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


    function validate(){
        let res = parseInt(count)
        if(zid != "" && password != "" && count !="" && cname != ""){

            if(res < 0 ){
                alert("Invalid Count")
            }else{

                if(count < prevcount){
                    alert("Count Cannot Be Lower")
                }
                else{
                   
                    updatechit()
                }

            }

           

        }else{
            alert("Enter All Feilds")
        }
    }

    useEffect(() => {
     getchit()
    }, [])


    async function updatechit(){
        const washingtonRef = doc(db, "chitGroups", ""+urlParams.get('cid'));
        try{

            await updateDoc(washingtonRef, {
                chitName: cname,
                meetId: zid,
                meetPassword: password,
                usersAllowed: count
                });
                window.history.back()
        }catch(e){
            setprogress(false)
            alert("Error "+e )
        }
    }
    
    async function getchit(){
        setprogress(true)

        const docRef = doc(db, "chitGroups", ""+urlParams.get('cid'));
        const docSnap = await getDoc(docRef);
        setprogress(false)

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setcname(""+docSnap.data().chitName)
            setzid(""+docSnap.data().meetId)
            setpassword(""+docSnap.data().meetPassword)
         
            setcount(""+docSnap.data().usersAllowed)
            setprogress(false)
            let t1 = parseInt(""+docSnap.data().usersAllowed)
            setprevcount(t1)
           
            
          } else {
            setprogress(false)
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
       
    }

  return (
    <div style={{padding: '20px'}}>

        {
            progress ? <Progress/> : null
        }

    <h2> Edit Chit:- {urlParams.get('name')} </h2>
     <div className="container" >
         <label>Enter Name</label>
         <input type="text" value={cname} onChange={(x)=>{setcname(x.target.value)}} placeholder="Enter Name"/>

         <label>Enter Meet Id</label>
         <input type="text" value={zid} onChange={(x)=>{setzid(x.target.value)}} placeholder="Enter Meet Id"/>

         <label>Enter Meet Password</label>
         <input type="text" value={password} onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Meet Password"/>


         <label>Enter UserCount</label>
         <input type="number" value={count} onChange={(x)=>{setcount(x.target.value)}} placeholder="Enter UserCount"/>

         <button onClick={()=>{validate()}}>Submit</button>
    </div>
    </div>
  )
}

export default ChitEdit