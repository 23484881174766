import React, { useEffect, useState, useRef } from 'react'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, getDoc, where, doc, query } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import { BrowserRouter, Link, Router, useNavigate } from 'react-router-dom';
import NewChits from './NewChits';
import { ref } from 'firebase/storage';



function NewHome() {
    
    let navigate = useNavigate()

    const [title, settitle] = useState();
    const [body, setbody] = useState()
    const db = getFirestore(app)
    const myauth = getAuth(app)

    const [progress, setprogress] = useState(true)
    const [arr, setarr] = useState([])
    const [searchtxt, setsearchtxt] = useState("")
    const [arr2, setarr2] = useState([])
    const [show, setshow] = useState(false)
    const fref = useRef()

    const myarray = []
    let array2 = []

    

    useEffect(() => {
        getagents()
    }, [])
    
    function logout(){
        console.log("out")
        myauth.signOut();
    }

    async function searchagent(name){
  
      setshow(true)
      array2 = []
      setarr2([])
      
      const q = query(collection(db, "agents"), where("email", ">=", ""+name), where("email", "<=", name +'\uf8ff'));
     
      const querySnapshot = await getDocs(q)

      console.log(querySnapshot.size)
      querySnapshot.forEach((doc1) => {
        console.log(doc1.id, " => ", doc1.data());
        let obj1 = {
          email: doc1.data().email,
          name: doc1.data().name,
          companyName: doc1.data().companyName,
          password: doc1.data().password
        
        }
        array2.push(obj1)
       console.log(array2)
      });
      
      const q2 = query(collection(db, "users"), where("email", ">=", ""+name), where("email", "<=", name +'\uf8ff'));
      const querySnapshot2 = await getDocs(q2)
      querySnapshot2.forEach((doc2) => {
        console.log(doc2.id, " => ", doc2.data());
        let obj1 = {
          email: doc2.data().email,
          name: doc2.data().name,
          companyName:"user",
          password: doc2.data().password
        
        }
        array2.push(obj1)
       console.log(array2)
      
      });

      setarr2(array2)

    }



   async function getagents() {

  

        const querySnapshot = await getDocs(collection(db, "agents"));
       setprogress(false)
        querySnapshot.forEach((doc) => {
           //  console.log(`${doc.id} => ${doc.data()}`);
             
           
           console.log(doc.data(), false)


           let mymodel = {mydata: doc.data(), mystatus: false}
           //  myarray.push(doc.data())
             myarray.push(mymodel)
           
           
        });

        setarr(myarray)
    }

    return (
        <div style={{padding:'20px'}}>
            {
                progress ?
                   <Progress/>

                       : null
            }

       

            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '12px' }}>

            <button onClick={()=>{navigate("/create")}}>Create Agent</button>
            <button onClick={()=>logout()}>Logout</button>
          

            </div>

            <h1 style={{textAlign: 'center'}}>Notifications</h1>

            {/* <div>
            <input onChange={(x)=>{setsearchtxt(x.target.value)}} value={searchtxt} style={{width: '180px', padding: '4px' }} type='text' placeholder='search here'/>
            <button onClick={()=>{searchagent(searchtxt)}}>Submit</button>
            <button onClick={()=>{setshow(false); setsearchtxt("")}}>Clear</button>
            </div> */}


            <div>
              <label>Title</label>
              <input
              value={title}
              onChange={(x)=>{
                  settitle(x.target.value)
                
              }}
              placeholder="Enter Title"
               type='text'/>
              <label>Body</label>
              <input 
              placeholder='Enter Body'
               onChange={(x)=>{
                setbody(x.target.value)
            }}
              type={'text'}/>
            </div>
           
     


 

<div style={{display: show ? 'none' : 'block'}}>


            <table  id="customers">
                            <thead>
                    
                            <tr>
                            
                              <th>Name</th>
                              <th>Company Name</th>
                              
                              <th>Password</th>
                              <th style={{display: 'none'}}>Mobile</th>
                              <th>Edit</th>
                             
                            </tr>
                            </thead>
                            <tbody
                            >
                    
                            {arr.map((x, key)=>{


                            
                              const row =  <tr  
                               key={key}
                               
                               onClick={()=>{
                                if(x.mystatus == false){
                                  console.log("clicked")
                                  let old = [...arr]
                                  old[key].mystatus = true
                                  setarr(old)
                              
                                  fref.current.printme()
                                //mref.current()
                                // ref.child.printme()
                                }else{
                                  let old = [...arr]
                                  old[key].mystatus = false
                                  setarr(old)
                                }
                              
                              }
                              }
                               >
                              <td


                       
                              
                              // onClick={()=>{
                              //     navigate('/details?uid='+x.mydata.uid+'&name='+x.mydata.name)
                              //    }} 
                                 style={{color: 'blue', textDecorationLine: 'underline', cursor: 'pointer'}}
                                 >{x.mydata.name}</td>
                         
                              <td>{x.mydata.companyName}</td>
                             
                              <td>{x.mydata.password}</td>
                              <td style={{display: 'none'}}>{x.mydata.mobile}</td>
                              <td><button 
                              onClick={()=>{navigate('/edit?id='+x.mydata.uid)}}
                              style={{margin: '12px'}}>Edit</button></td>



                            

                            </tr>
                              return<React.Fragment>
                                {row}
                                {
                                  x.mystatus ? <NewChits  uid={x.mydata.uid} name={x.mydata.name}
                                  title={title} body={body}/> : null
                                }
                              </React.Fragment>
                       
                          
                            })}
                    
                            </tbody>
                           
                          </table>

                          </div>

                        <div style={{display: show ? 'block' : 'none'}}>

                        <table id="customers">
                            <thead>
                    
                            <tr>
                            
                              <th>Name</th>
                              <th>Company Name</th>
                              
                              <th>Password</th>
                              <th style={{display: 'none'}}>Mobile</th>
                           
                             
                            </tr>
                            </thead>
                            <tbody>
                    
                            {arr2.map((x, key)=>{

                              const row =          <tr   key={key}>
                              <td>{x.email.replace("@gmail.com","")}</td>
                         
                              <td>{x.companyName  == null? "user": x.companyName}</td>
                             
                              <td>{x.password}</td>
                              <td style={{display: 'none'}}>{x.mobile}</td>
                           
                            

                            </tr>
                              return <React.Fragment>

                                {row}
                                
                          

                              </React.Fragment>
                                
                     
                                

                              
                            })}
                    
                            </tbody>
                           
                          </table>

                        </div>
                          


        </div>
    )
}

export default NewHome