import React, { useEffect, useState } from 'react'
import { addDoc, collection, getFirestore, serverTimestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { async } from '@firebase/util';
import { app } from '../Fire';
import Progress from '../Progress/Progress';
import { getAuth } from 'firebase/auth';

const EditAgent = () => {



    const db = getFirestore(app)
    const auth = getAuth(app)

    const [progress, setprogress] = useState(true)

    
    const [email, setemail] = useState("faz")
    const [password, setpassword] = useState("")
    const [name, setname] = useState("")
    const [mobile, setmobile] = useState("")
    const [cname, setcname] = useState("")
    const [remarks, setremarks] = useState("")
    


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


   async function validate(){
        if(password != "" && cname != "" && name != "" && remarks != "" ){
       
      
        changepass()

        }else{
          
            alert("Please Fill All Fields")
        }
      
    }

    useEffect(() => {
     getagent()
    }, [])
    

    async function updatefields(){
        const washingtonRef = doc(db, "agents", ""+urlParams.get('id'));

        try{

            await updateDoc(washingtonRef, {
                name: name,
                companyName: cname,
                password: password,
                remarks: remarks
                });
                window.history.back()
        }catch(e){
            setprogress(false)
            alert("Error "+e )
        }
    }

   async function changepass(){
    setprogress(true)
        const mybody = {
            "uid" : urlParams.get('id'),
            "password" : password+""
            }
    
    
            await fetch('https://us-central1-zoomchits.cloudfunctions.net/onPassword2', {
                method: 'POST',
    
                headers: {
    
    
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mybody)
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data.status == 1) {
                        updatefields()
                    alert("done")
                    
                  
                    }
                    else {
                        alert(data.message)
                    
                    }
    
    
        })
        .catch((e) => {
          
            alert("Technical Error: " + e)
            console.log("error", e)
        })
    }

   async function getagent(){

        const docRef = doc(db, "agents", ""+urlParams.get('id'));
        const docSnap = await getDoc(docRef);
        setprogress(false)

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setname(""+docSnap.data().name)
            setcname(""+docSnap.data().companyName)
            setpassword(""+docSnap.data().password)
            setremarks(""+docSnap.data().remarks)
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
       
    }
    
   

  return (
    <div >
    

    {
        progress ?    <Progress/> : null
    }
     
  
<div style={{display: 'flex', justifyContent: 'space-between', margin: '20px'}}>

    <button onClick={()=>{window.history.back()}}>Go Back</button>

    <button onClick={()=>{auth.signOut()}} id="logout">Logout</button>

</div>

<div style={{padding: '20px'}}>

<h3>Edit Agent</h3>

    <div className="container">

        
        <div> 
        <label>AgentId <span style={{color: '#5286a1'}}>(Non Editable)</span></label>
            <input type="text" value={name}
            disabled={true}
             onChange={(x)=>{setname(x.target.value)}} placeholder="Enter AgentId"/>

         

            <label>Enter Company Name</label>
            <input type="text" value={cname} onChange={(x)=>{setcname(x.target.value)}} placeholder="Enter Company Name"/>

           

            <label>Enter Password</label>
            <input type="text" value={password} onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Password"/>

            <label>Enter Remarks</label>
            <input type="text" value={remarks} onChange={(x)=>{setremarks(x.target.value)}} placeholder="Enter Remarks"/>

    
     

        <button onClick={()=>{
            validate()
        }}>Submit</button>

</div>

</div>

</div>

</div>

  )
}

export default EditAgent