import React, { useEffect, useState } from 'react'
import './login.css'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { app } from '../Fire';
import Progress from '../Progress/Progress';
import { useNavigate } from 'react-router-dom';




const Login = () => {




  let navigate = useNavigate()


  const auth = getAuth();
  useEffect(() => {
    checkauth()
  }, [])
  


  function checkauth(){
    
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
       
        console.log("helo")
  
   
        navigate('/home')
     
      } else {
       // window.location = 
       
       console.log("no login")
        
        navigate('/')
     
      }
    });
  }

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")



  const [progress, setprogress] = useState(false)

  function test(){
    setprogress(true)
    signInWithEmailAndPassword(auth, email+"@gmail.com", password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential)
          setprogress(false)
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(error)
          setprogress(false)
          // ..
        });
  }


  return (
    <div>


      {
        progress ? <Progress/>
        : null
      }
        <h1 style={{textAlign: 'center', marginBottom: '20px'}}>Admin login page (v 1.5)</h1>

        <form>
   <div className="container">
    <label ><b>Username</b></label>
    <input type="text"  onChange={(x)=>{setemail(x.target.value)}} placeholder="Enter Username" name="uname" required/>

    <label><b>Password</b></label>
    <input type="password" onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Password" />
   
  </div>

  <div className="container" style={{backgroundColor: '#f1f1f1', display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
    <button
      onClick={()=>test()}
     type="button" className="cancelbtn">Submit</button>
   
  </div>
</form>

    </div>

  )
}

export default Login