import React, { useEffect, useState } from 'react'
import { getFirestore, collection, getDocs, where, query, doc } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import { useNavigate } from 'react-router-dom';
import { app } from '../Fire';

function NewUsers({mchitid, title, body, chitname}) {

    const [userarray, setuserarry] = useState([])
    const [progress, setprogress] = useState(false)

    let carray = []

    const db = getFirestore(app)
    
    useEffect(() => {
    getchit(mchitid)
    }, [])
    


    async function sendnoti(uid) {

        setprogress(true)
  
        const mainbody = {
  
            to: "/topics/" +uid,
            notification: {
                title: ""+title,
                body: ""+body
            }
        
        }
  
        await fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAA9KmJSrg:APA91bEIr4vDKoLaCfaFVN6ef_2tpLiE-YtLIESdTCybyPBgze4Eh9LSdB2jIRHb0uUGVWSihlaQjfeAOGIp7qeucISkHwp0PsDT30vTj30hAD5v0QIRFq6ww3XphktMZNljNR0coU43'
            },
            body: JSON.stringify(mainbody)
        }).then(response => response.json())
            .then(data => {
                console.log(data)
  
                setprogress(false)
                alert("Alert Sent")
                console.log(data)
               
            })
            .catch((e) => {
              setprogress(false)
                alert(e)
              
            })
  
  
    }
  

    async function getchit(chitid){
        setprogress(true)
        const q = query(collection(db, "chitUsers"), where("chitId", "==", chitid));
        const querySnapshot = await getDocs(q)
      
      
        console.log(querySnapshot.docs)
        querySnapshot.forEach((doc)=>{

          carray.push(doc.data())
          console.log("item" + doc.data().name)
        
        })
     setprogress(false)
       setuserarry(carray)
     
      }

  return (
    <div>
        
    {
        progress ? <Progress/> : null
    }
        <table 
                                      
                 style={{display: userarray.length == 0 ? 'none': null, margin: '12px'}}
                                      id="customers"> 
                                              <thead>
                                        
                                                <tr>
                                                  <th>Chit Name</th>
                                                  <th>Email</th>
                                                  <th>Password</th>
                                                  
                                                  <th>Remark</th>
                                                  <th>Notify</th>
                                             
                                                </tr>
                                                </thead>
                                                <tbody> 
                                               </tbody>
                                               
                                               {
                                                  userarray.map((x, key)=>{
                                                    return (
                                                      <tr key={key} >
                                                    <td >{chitname}</td>
                                                    <td>{x.email}</td>
                                                    <td>{x.password}</td>
                                                   
                                                    <td>{x.remark}</td>
                                                    <td>
                                                        
                                                        <button
                                                        onClick={()=>{
                                                         
                                                          if(title == undefined || body == undefined){
                                                            alert("Please Enter Title and Body")
                                                          }else{
                                                            sendnoti(x.uid)
                                                          
                                                          }
                                                        
                    
                                                        }}
                                                        >
                                                            +
                                                        </button>
                                                    </td>
                                                 
                    
                                                  </tr>
                                         
                                                    )
                                                  })
                                               }
                                              </table> 
                                              

    </div>
  )
}

export default NewUsers