import logo from './logo.svg';
import Test from './Test/Test';
import { app } from './Fire'
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { async } from '@firebase/util';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import Home from './Dashboard/Home';
import CreateAgent from './CreateAgent/CreateAgent';
import { Routes, Route, useRoutes, BrowserRouter, Router, useNavigate } from "react-router-dom";
import AgentDetails from './AgentDetails/AgentDetails';
import ChitDetails from './ChitDetails/ChitDetails';
import Page1 from './TestFolder/Page1';
import Page2 from './TestFolder/Page2';
import Home1 from './TestFolder/Home1';
import Login from './Login/Login';
import EditAgent from './EditAgent/EditAgent';
import CreateChit from './CreateChit/CreateChit';
import ChitEdit from './ChitEdit/ChitEdit';
import AccountPage from './AccountPage/AccountPage';
import AddAccount from './AddAccount/AddAccount';
import NewHome from './NewHome/NewHome';
import Editlogo from './EditLogo/Editlogo';



function App() {

  
  let navigate = useNavigate()
  const [islog , setislog ] = useState()
  const auth = getAuth();



  useEffect(() => {
    checkauth()
  }, [])
  


  function checkauth(){
  
  

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
       
        console.log("helo")
        setislog(true)
        
        if(window.location.href == "/"){
          navigate('/home')
        }
       
     
      } else {
       // window.location = 
        setislog(false)
       console.log("no login")
        
        navigate('/')
     
      }
    });
  }
  
 



  return (
    <div>
    <Routes>
  
      <Route index element={<Login/>} />
      <Route path='/home' element={<Home/>}></Route>
      <Route path="/create" element={<CreateAgent/>}></Route>
      <Route path="/details" element={<AgentDetails/>}></Route>
      <Route path='/chitdetails' element={<ChitDetails/>}></Route>
      <Route path='/edit' element={<EditAgent/>}></Route>
      <Route path='/createchit' element={<CreateChit/>}></Route>
     <Route path='/editchit' element={<ChitEdit/>}></Route>
     <Route path='/accounts' element={<AccountPage/>}></Route>
      <Route path='/addaccount' element={<AddAccount/>}></Route>
      <Route path='/newHome' element={<NewHome/>}></Route>
      <Route path='/editLogo' element={<Editlogo/>}></Route>
      </Routes>


      
    </div>
  
  );
}

export default App;
