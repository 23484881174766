import React, { useEffect, useState } from 'react'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, where, query, doc } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import { useNavigate } from 'react-router-dom';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import './agent.css'

function AgentDetails() {

    let navigate = useNavigate()

    const db = getFirestore(app)
    const auth = getAuth(app)

    const [temp, settemp] = useState("")
    const [progress, setprogress] = useState(true)
    
    const [arr, setarr] = useState([])
    const [userarray, setuserarry] = useState([])

    const [borderstatus, setborderstatus] = useState("")

    const myarray = []
    const carray = []

    useEffect(() => {
        getagents()
    }, [])
           const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);


        async function getchit(chitid){
          setprogress(true)
          const q = query(collection(db, "chitUsers"), where("chitId", "==", chitid));
          const querySnapshot = await getDocs(q)
        
        
          console.log(querySnapshot.docs)
          querySnapshot.forEach((doc)=>{

            carray.push(doc.data())
            console.log("item" + doc.data().name)
          
          })
          setprogress(false)
         setuserarry(carray)
       
        }

    async function getagents() {

        const q = query(collection(db, "chitGroups"), where("uid", "==", urlParams.get('uid')));
        const querySnapshot = await getDocs(q);
       setprogress(false)
        querySnapshot.forEach((doc) => {
           //  console.log(`${doc.id} => ${doc.data()}`);
             
           console.log(doc.data())

             myarray.push(doc.data())
           
           
        });

        setarr(myarray)
    }

  return (
    <div>

<div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>

           

<button onClick={()=>{window.history.back()}}> Back </button>
<button onClick={()=>{auth.signOut()}}>Logout</button>

</div>
        <h1 style={{margin: '16px'}}>{"Agent Name:- "+ urlParams.get('name')} </h1>

      <div style={{display: 'flex',  justifyContent: 'end'}}>
      <button onClick={()=>{ navigate('/createchit?uid='+urlParams.get('uid')) }} 
      style={{ marginLeft: '16px', marginRight: '12px'}}>Create Chit</button>
      </div>
       


        <div style={{padding:'20px'}}>
            {
                progress ?
                  <Progress/>
                       : null
            }


            {/* <div style={{width: '100px', height: '100px', backgroundColor: 'green',border: '2px solid black'}}>

            </div> */}


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

           

         
            </div> 
                   <ScrollMenu>
                   {arr.map((x, key)=>{
                              return(

                             
                                <div 
                                key={key} 
                                onClick={()=>{
                                  settemp(x.chitName)
                              
                                  getchit(x.chitId)
                                  setborderstatus(x.chitName)
                                }}
                                style={{ width: '120px', background: '#'+x.chitColor, margin: '10px', padding: '5px',
                              border: x.chitName == borderstatus ? '2px solid black': '0px solid white' , borderRadius: '10px'}}> 


                            

                                <p style={{color: 'black', marginTop: '-3px'}}>{x.chitName}</p>
                                <p style={{color: 'black', marginTop: '-10px'}}>{x.count} <b>/</b> {x.usersAllowed}</p>
                              
                                <button 
                                style={{marginTop: '-10px'}}
                                onClick={()=>{
                                 navigate('/editchit?cid='+x.chitId+'&name='+x.chitName)
                                }}> edit </button>
                                  <button
                      onClick={()=>{
                        navigate('/accounts?cid='+x.chitId+'&cname='+x.chitName)
                      }}
                      >Accounts</button>
                                </div>

                                
                             
                              )
                            })}
                   </ScrollMenu>
                        
                    
                  <table id="customers"> 
                          <thead>
                    
                            <tr>
                              <th>Chit Name</th>
                              <th>Email</th>
                              <th>Password</th>
                              
                              <th>Remark</th>
                         
                            </tr>
                            </thead>
                            <tbody> 
                           </tbody>
                           
                           {
                              userarray.map((x, key)=>{
                                return (
                                  <tr key={key} >
                                <td >{temp}</td>
                                <td>{x.email}</td>
                                <td>{x.password}</td>
                               
                                <td>{x.remark}</td>
                             

                              </tr>
                     
                                )
                              })
                           }
                          </table> 


        </div>
   


    </div>


  )
}

export default AgentDetails