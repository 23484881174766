import { async } from '@firebase/util';
import { collection, doc, getDoc, getFirestore, query, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { app } from '../Fire';
import Progress from '../Progress/Progress';

function Editlogo() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    const [progress, setprogress] = useState(false)
   const db = getFirestore(app)
    const [mlogo, setmlogo] = useState(urlParams.get('logo'))

    const [selectedImage, setSelectedImage] = useState(null);


    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    useEffect(() => {
        getlogo()
    }, [])


    async function uploadstorage(){
        const storage = getStorage(app)
        const storageRef = ref(storage, `/files/${""+makeid(20)}`)
     
        setprogress(true)
    
        
            const metadata = {
                contentType: 'image/jpeg',
              };
        
              const uploadTask = uploadBytesResumable(storageRef, selectedImage );
    
    
              uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                }
            }, 
            (error) => {
                // Handle unsuccessful uploads
                alert(error)
                setprogress(false)
            }, 
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               let duri = downloadURL
                console.log('File available at', downloadURL);
              
                changeLogo(duri)
               // userservice()
                });
            }
            );
              
        }
    


    async function changeLogo(myuri){

        const washingtonRef = doc(db, "agents", ""+urlParams.get('id'));

        try{

            await updateDoc(washingtonRef, {
                logo: myuri
                });
                window.history.back()
        }catch(e){
            setprogress(false)
            alert("Error "+e )
        }
    }


    async function getlogo(){
        const q = query(doc(db, "agents", ""+urlParams.get('id')))
        const snapshot = await getDoc(q)
       console.log(snapshot.data().logo)
       setmlogo(snapshot.data().logo)
    
    }
    

  return (
    <div style={{padding: '12px'}}>

        {
            progress ? <Progress/> : null
        }
        <h4
        onClick={()=>{
            alert(urlParams.get('logo'))
        }}
        >Edit Your Logo</h4>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

    
        <img 
        src={mlogo}
        style={{width: '400px', height: '400px', margin: '12px'}}
        />


                    <input
                type="file"
                accept="image/*"
                style={{margin: '12px 0px 12px 0px'}}
                name="myImage"
                onChange={(event) => {

                    const [file] = event.target.files;
                 //   setmyimg(URL.createObjectURL(file));
        
                    setmlogo(URL.createObjectURL(file))
                console.log(event.target.files[0].name);
    
                setSelectedImage(event.target.files[0]);
                
                }}
            />

        <button
        onClick={()=>{

            uploadstorage()
        }}
        style={{padding: '4px', borderRadius: '12px'}}
        >Change Logo</button>
        </div>
      
 
    </div>
  )
}

export default Editlogo