import React from 'react'
import { Link } from 'react-router-dom'

function Page2() {
  return (
    <div>Page2
          <Link to='/'>Home</Link>
          <button onClick={()=>{ window.history.back()}}> Button back </button>
    </div>
  )
}

export default Page2