import React, { useState } from 'react'
import { app } from '../Fire'
import './createchit.css'
import { addDoc, collection, doc, getFirestore, serverTimestamp, setDoc, writeBatch } from "firebase/firestore";
import { async } from '@firebase/util';
import Progress from '../Progress/Progress';

const CreateChit = () => {


   const randColor = () => {
            return "" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
        }
    
        
        const makeid = (length) => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }

    const db = getFirestore(app);
    const [zid, setzid] = useState("")
    const [password, setpassword] = useState("")
    const [nclients, setnclients] = useState(0)
    const [name, setname] = useState("")
    const [progress, setprogress] = useState(false)
    const chitid = makeid(20)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    function validate(){
        let res =  parseInt(nclients) 

        if(name != "" && password != "" && nclients != "" && res !=0 && name != ""){
           makechit()
        }else{
            if(res == 0){
                alert("Enter Valid User Count, Should be Greater than 0")
            }else{
                alert("Enter all Feilds")
            }
           
        }
    }

   async function makechit(){
       setprogress(true)
        try {
            const docRef = await setDoc(doc(db, "chitGroups", chitid), {
                chitName: "" + name,
                uid: "" + urlParams.get('uid'),
                count: 0,
                amount: 0,
                createdDate: serverTimestamp(),
                chitId: chitid,
                meetId: zid,
                meetPassword: password,
                meetMessage: "",
                chitColor: randColor(),
                usersAllowed: nclients

            });

            alert("Your Chit has been Created");

            window.history.back()




        } catch (e) {

            alert("Error", e)
            setprogress(false)
            console.error("Error adding document: ", e);
        }

    }

  return (
      <div style={{padding: '20px'}}>

          {
              progress ? <Progress/> : null
          }

        <h2> Create Chit </h2>
         <div className="container" >
           <form>
                <label>Enter Zoom id</label>
                <input type="text" onChange={(x)=>{setzid(x.target.value)}} placeholder="Enter Zoom Id"/>

                <label>Enter Zoom Password</label>
                <input type="text" onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Password"/>

                <label>Enter UserCount<span>(Max Users Allowed to Add)</span></label>
                <input type="number" value={nclients} onChange={(x)=>{setnclients(x.target.value)}} placeholder="No.of Clients"/>

                <label>Enter ChitName</label>
                <input type="text" onChange={(x)=>{setname(x.target.value)}}  placeholder="Enter ChitName"/>
                </form>
                <button onClick={()=>{validate()}}>Submit</button>
        </div>

       
      </div>
  
  )
}

export default CreateChit