import React, { useEffect, useState } from 'react'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, where, query } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth} from "firebase/auth"
import Progress from '../Progress/Progress';
import './chit.css'

function ChitDetails() {

    const db = getFirestore(app)
    const auth = getAuth(app)

    const [progress, setprogress] = useState(true)
    const [arr, setarr] = useState([])

    const myarray = []

    useEffect(() => {
        getagents()
    }, [])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    async function getagents() {

       
    
        const q = query(collection(db, "chitUsers"), where("chitId", "==", urlParams.get('cid')));
        const querySnapshot = await getDocs(q);
       setprogress(false)
        querySnapshot.forEach((doc) => {
           //  console.log(`${doc.id} => ${doc.data()}`);
             
           console.log(doc.data())

             myarray.push(doc.data())
           
           
        });

        setarr(myarray)
    }

  return (
    <div>


<div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>

           

<button onClick={()=>{window.history.back()}}> Back </button>
<button onClick={()=>{auth.signOut()}}>Logout</button>

</div>

        <h1 style={{marginLeft: '20px'}}>{"Chit Details:- "+urlParams.get('name') }</h1>


        <div style={{padding:'20px'}}>
            {
                progress ?
                  <Progress/>
                       : null
            }

            
            <table id="customers">
                            <thead>
                    
                            <tr>
                              <th>Chit Name</th>
                              <th>Email</th>
                              <th>Password</th>
                              <th>Name</th>
                              <th>remark</th>
                            </tr>
                            </thead>
                            <tbody>
                    
                            {arr.map((x, key)=>{
                              return(
                             <div>
                                <tr key={key}>
                                <td >{x.chitName}</td>
                                <td>{x.email}</td>
                                <td>{x.password}</td>
                                <td>{x.name}</td>
                                <td>{x.remark}</td>

                              </tr>
                             </div>
                             
                             
                              )
                            })}
                    
                            </tbody>
                           
                          </table>


        </div>
   


    </div>


  )
}

export default ChitDetails