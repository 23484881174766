
import React, { useState } from 'react'
import { addDoc, collection, doc, getFirestore, serverTimestamp, setDoc, writeBatch } from "firebase/firestore";

import { app } from '../Fire';
import Progress from '../Progress/Progress';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";

import './createagent.css'


const CreateAgent = () => {

    
    var duri;


    const [selectedImage, setSelectedImage] = useState(null);


    const randColor = () => {
        return "" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
    }

    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }


    const auth = getAuth(app)
    const storage = getStorage(app)
    const storageRef = ref(storage, `/files/${""+makeid(20)}`)
 

    function uploadfun(dataid){
       
        const metadata = {
            contentType: 'image/jpeg',
          };
    
          const uploadTask = uploadBytesResumable(storageRef, selectedImage );


          uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            duri = downloadURL
            console.log('File available at', downloadURL);
            addData(dataid)
           // userservice()
            });
        }
        );
          
    }
  


    const [progress, setprogress] = useState(false)
    const [myimg, setmyimg] = useState();

    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    var chitId = makeid(20)
    const [remarks, setremarks] = useState("")
    const [cname, setcname] = useState("")
    const [zid, setzid] = useState("")
    const [zpassword, setzpassword] = useState("")
    const [nclients, setnclients] = useState("")
    const [name, setname] = useState("")


    const db = getFirestore(app)

    function validate(){
      
        if(email != "" && remarks != ""  && password != "" && cname != "" && selectedImage != null){
            setprogress(true)
           userservice()
        }else{
          
            alert("Please Fill All Fields")
        }
      
    }

  async function userservice() {
    setprogress(true)
    const mybody = {
        email: email+"@gmail.com",
        password: password,
        name: email
    }

    await fetch('https://us-central1-zoomchits.cloudfunctions.net/createNewUser', {
        method: 'POST',

        headers: {

            'Content-Type': 'application/json'
        },
        body: JSON.stringify(mybody)
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.status == 1) {
             uploadfun(data.uid)
               
               
            }
            else{
            let custom = String(data.message).replace("email address", "Agent id")
             alert(custom)
             setprogress(false)
            }
            

        })
        .catch((e)=> {
           
            alert("Technical Error: "+e)
            console.log("error", e)
        })
}

 


        async function addData(uid){

            // const mydata = {
            //     name: email,
            //     remarks: remarks,
            //     uid: ""+uid,
            //     companyName: cname,
            //     email: email+"@gmail.com",
            //     password: password,
            //     createdAt: serverTimestamp(),
            //     chitsAllowed: 20
            // }


            await setDoc(doc(db, "agents", ""+uid), {
                name: email,
                remarks: remarks,
                uid: ""+uid,
                companyName: cname,
                email: email+"@gmail.com",
                password: password,
                createdAt: serverTimestamp(),
                chitsAllowed: 20, 
                logo: duri,
                lock: false
            })


         
           

       

            
            window.history.back()


            // try {
            //     const docRef = await setDoc(doc(db, "agents", ""+uid),{

            //         name: email,
            //         remarks: remarks,
            //         uid: ""+uid,
            //         companyName: cname,
            //         email: email+"@gmail.com",
            //         password: password,
            //         createdAt: serverTimestamp(),
            //         chitsAllowed: 20

            //     })
               
            //     window.history.back()
            //     setprogress(false)
            //     console.log("Document written with ID: ", docRef.id);
            //   } catch (e) {
            //     setprogress(false)
            //     console.error("Error adding document: ", e);
            //   }
        }

  return (
    <div >
    

        {
            progress ?    <Progress/> : null
        }
         
      
    <div style={{display: 'flex', justifyContent: 'space-between', margin: '12px'}}>

        <button onClick={()=>{window.history.back()}}>Go Back</button>

        <button onClick={()=>{auth.signOut()}} id="logout">Logout</button>

    </div>

    <div style={{padding: '20px'}}>

<h3>Create New User</h3>

        <div className="container">

            <form>
                <label>Agent ID <span
                style={{color: '#60a3a6', fontSize: '12px'}}>(Cannot Modify Later)</span> </label>
                
                <input type="text" onChange={(x)=>{setemail(x.target.value)}} placeholder="Enter Username"/>

                <label>Enter Company Name</label>
                <input type="text" onChange={(x)=>{setcname(x.target.value)}} placeholder="Enter Company Name"/>

                <label>Enter Password</label>
                <input type="text" onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Password"/>

                <label>Enter Remarks</label>
                <input type="text" onChange={(x)=>{setremarks(x.target.value)}}  placeholder="Enter Remarks"/>
                

                
                <input
                type="file"
                accept="image/*"
                style={{margin: '12px 0px 12px 0px'}}
                name="myImage"
                onChange={(event) => {

                    const [file] = event.target.files;
                    setmyimg(URL.createObjectURL(file));
        
                console.log(event.target.files[0].name);
    
                setSelectedImage(event.target.files[0]);
                
                }}
            />

            <img src={myimg} style={{width: 40, height: 40}}  alt="" />
                
                {/* <p>Groups</p>

             
                <label>Zoom id</label>
                <input type="text" onChange={(x)=>{setzid(x.target.value)}} placeholder="Enter Zoom id"/>

                <label>Zoom Password</label>
                <input type="text" onChange={(x)=>{setzpassword(x.target.value)}} placeholder="Enter Password"/>

                <label>No of Clients</label>
                <input type="text" onChange={(x)=>{setnclients(x.target.value)}}  placeholder="No of Clients"/>


                <label>Name</label>
                <input type="text" onChange={(x)=>{setname(x.target.value)}}  placeholder="Enter Name"/> */}
               
            </form>


       
            <button onClick={()=>{validate()}}>Submit</button>

        
  
  </div>

    </div>
  
    </div>

  
  )
}

export default CreateAgent